import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Projectcard from "./projectcard";

const ProjectOne = () => {
  const ProjectData = useStaticQuery(graphql`
    query ProjectDataQuery {
      allProjectJson(limit: 6, sort: {fields: id, order: DESC}) {
        edges {
          node {
            id
            title
            category
            featured_image {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 341, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  `);

  const projectsData = ProjectData.allProjectJson.edges;

  return (
    <div>
      {projectsData.map((data) => (
        <Projectcard
          key={data.node.id}
          // column="col-lg-1 col-md-1 col-1"
          portfolioStyle="portfolio-style  -1"
          id={data.node.id}
          image={data.node.featured_image.childImageSharp}
          title={data.node.title}
          category={data.node.category}
        />
      ))}
    </div>
  );
};

export default ProjectOne;
