import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/homedefault/banner";
import About from "../components/homedefault/about";
import Project from "../components/homedefault/project";
import { useStaticQuery, graphql } from "gatsby";
// import Testimonial from "../components/homedefault/testimonial";
// import Service from "../components/homedefault/service";
// import Brand from "../components/homedefault/brand";
// import BlogPost from "../components/blogPost";
import Contact from "../elements/contact/contact";

const IndexPage = () => {
  const indexQueryData = useStaticQuery(graphql`
    query {
      fileName: file(relativePath: { eq: "images/banner/person-image.jpg" }) {
        childImageSharp {
          fixed(quality: 100, width: 518, height: 480) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const image = indexQueryData.fileName.childImageSharp.fixed;

  return (
    <Layout>
      <SEO title="Matthias Neumayer" image={image} />
      <Banner />
      <div className="portfolio-id" id="portfolio">
        <Project />
      </div>
      <About />
      <Contact />
    </Layout>
  );
};
export default IndexPage;
